<template>
  <div class="feedbacklist common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:反馈中心') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:反馈列表') }}</span>
      </template>
      <template slot="queryList">
        <div class="selectFilter">
          <div>
            <div class="title">{{ $t('dc:产品名称') }}</div>
            <el-select
              v-model="pageConfig.projectId"
              filterable
              :clearable="true"
              :placeholder="$t('dc:请选择')"
              @change="queryApi"
              class="mgR16"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            <div class="title">{{ $t('dc:反馈类别') }}</div>
            <el-select
              v-model="pageConfig.categoryId"
              filterable
              :clearable="true"
              :placeholder="$t('dc:请选择')"
              @change="queryApi"
              class="mgR16"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <div class="title">{{ $t('dc:状态') }}</div>
            <el-select
              v-model="pageConfig.status"
              filterable
              :clearable="true"
              :placeholder="$t('dc:请选择')"
              @change="queryApi"
              class="mgR16"
            >
              <el-option
                v-for="item in statusList"
                :key="item.status"
                :label="item.name"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="80" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:查看')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-look padding12 nicon"
                  @click="showDetail(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.status === true"
                effect="dark"
                :content="$t('dc:未解决')"
                placement="top"
              >
                <i
                  style="cursor: not-allowed; color: #ccc"
                  class="poc-icon-info poc-size16 el-icon-circle-check"
                ></i>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.status === false"
                effect="dark"
                :content="$t('dc:解决')"
                placement="top"
              >
                <i class="poc-size16 el-icon-circle-check" @click="recoverFeendback(scope.row)"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <FeedbackDialog
      v-if="showDetailDialog"
      :visible.sync="showDetailDialog"
      :rowData="rowData"
    ></FeedbackDialog>
  </div>
</template>
<script>
import FeedbackDialog from '@/views/dialog/feedbackDialog'
export default {
  name: 'surveylist',
  mixins: [$PCommon.TableMixin],
  components: {
    FeedbackDialog,
  },
  data() {
    return {
      title: '',
      content: '',
      pageConfig: {
        projectId: '',
        categoryId: '',
        status: '',
      },
      productList: [],
      loading: false,
      showDetailDialog: false,
      projectList: [],
      categoryList: [
        {
          name: 'Bug',
          id: 3,
        },
        {
          name: 'Exterior Interface',
          id: 2,
        },
        {
          name: 'Funtional Requirements',
          id: 1,
        },

        {
          name: 'System Fluency',
          id: 4,
        },
      ],
      statusList: [
        {
          status: true,
          name: 'Solved',
        },
        {
          status: false,
          name: 'Unsolved',
        },
      ],
      tableData: [],
      rowData: {},
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'feedbackId',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 40, // 默认宽度，像素
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 70,
        },
        {
          key: 'categoryName',
          name: this.$t('dc:反馈类别'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'submitTime',
          name: this.$t('dc:提交时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 90,
        },
        {
          key: 'userName',
          name: this.$t('dc:账号名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
        },
        {
          key: 'email',
          name: this.$t('dc:邮箱'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'statusName',
          name: this.$t('dc:状态'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 50,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getProductList()
      this.queryApi()
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.projectList = data
      }
    },
    async queryApi() {
      this.loading = true
      let { status, data, msg } = await $Api.feedback.getFeedbackList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
      this.loading = false
    },
    showDetail(row) {
      this.rowData = row
      this.showDetailDialog = true
    },
    async recoverFeendback(row) {
      let params = {
        feedbackId: row.feedbackId,
        status: !row.status,
      }
      let { status } = await $Api.feedback.updStatus(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.queryApi()
      }
    },
  },
}
</script>
<style lang="scss">
.selectFilter {
  display: flex;
  .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .mgR16 {
    margin-right: 16px;
    width: 180px;
  }
}
.feedbacklist .action-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
