<template>
  <div>
    <el-dialog :visible.sync="show" width="30%" top="10%" :close-on-click-modal="false" class="feedback-dialog "
      :title="$t('dc:反馈详情')" @close="closed">
      <div class="launchContent paddingB1">
        <div class="launchmsg">
          {{ dataMsg }}
        </div>
        <!-- <ul>
              <li v-for="item in data" >
                  <div>{{dataMsg}}</div>
              </li>
          </ul> -->
      </div>
      <!-- <span slot="footer" style="text-align: right;" ></span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'feedbackDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rowData: {}
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  data() {
    return {
      show: this.visible,
      dataMsg: '',
    }
  },
  created() {
    this.showDetail(this.rowData)
  },
  mounted() {
  },
  methods: {
    async showDetail(row) {
      let { status, data, msg } = await $Api.feedback.getFeedbackDetail({ feedbackId: row.feedbackId })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.dataMsg = data
      }
    },
    closed() {
      this.$emit('close')
      this.show = false
    }
  }
}
</script>
<style lang="scss" >
.launchContent {
  .launchmsg {
    background: #F4F4F4;
    max-height: 320px;
    height: 320px;
    padding: 8px;
    color: #000;
    line-height: 1.4;
    font-size: 12px;
    // width: 415px;
    overflow-y: scroll;
    // overflow-x: hidden;
    margin-bottom: 10px;
    border: 1px solid #CFD2D8;
  }
  .launchmsg::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;

  }
  .launchmsg::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
    background: #535353;
  }

  .launchmsg::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    // background: #EDEDED;
    background: transparent;
  }

  // li:hover{
  //     cursor: pointer;
  //     background: rgba(89, 226, 0, 1);
  //     div{
  //         color: #000;
  //         font-weight: 600;
  //     }
  // }
}
.paddingB1{
  padding-bottom: 10px;
}
</style>


